import React from 'react';
//import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
//import { BrowserRouter as Redirect } from "react-router-dom";

//import { withRouter } from 'react-router-dom';
//import Post from './Post';
import './img/ImgStyle.css';
import InputRange from 'react-input-range';
import './slider.css';
//import axios from 'axios';
//import connect from '@vkontakte/vkui-connect';
import { Offline, Online } from "react-detect-offline";



class Filters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			path: null,
			rubsarray: null,
			value: { min: this.props.mininvest[0][0], max: this.props.maxvalues[0][2] },
			rubrics: [],
			sort: [false, false, false, false, false, false, false, false],
			openrub: false,
			openinv: false,
			opensort: false,
			allcheck: false,
			test: null,
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let rubs = [];
		//ы   console.log(this.props)

		for (let i = 0; i < this.props.rubs.length; i++) {
			rubs.push({ name: this.props.rubs[i].name_rubs, check: false });
		}

		this.setState({ rubrics: rubs });

		if (this.props.thisrubs && this.props.thisminval && this.props.thismaxval && this.props.thissort) {
			let min = this.props.thisminval;
			let max = this.props.thismaxval;
			let sort = this.props.thissort;
			let therubs = this.props.thisrubs;
			this.setState({ value: { min, max } });
			if (sort == 'year') this.Radio(1);
			else if (sort == 'profit') this.Radio(2);
			else if (sort == 'investments') this.Radio(3);
			else this.Radio(0);
			for (let i = 0; i < therubs.length; i++) {
				rubs[rubs.findIndex(x => x.name === therubs[i])].check = true;
			}
		} else this.Radio(0);
	}



	Onchecked = (name) => {
		let rubs = this.state.rubrics;
		let rub = rubs.find(c => c.name === name);
		rub.check = !rub.check;
		if (rub.check) {
			if (rubs.findIndex(c => c.check === false) === -1)
				this.setState({ rubrics: rubs, allcheck: true })
			else
				this.setState({ rubrics: rubs })
		}
		else this.setState({ rubrics: rubs, allcheck: false })
		// let counter;
		// for(let i=0;i<rubs.length;i++){
		// 	if(rubs[i].check)
		// 	counter++;
		// }
		// if(counter==rubs.length)
		// this.setState({allcheck:true})
	}

	Allcheck = () => {
		let rubs = this.state.rubrics;
		for (let i = 0; i < rubs.length; i++)
			rubs[i].check = !this.state.allcheck;
		this.setState({ allcheck: !this.state.allcheck })
	}
	Rubhide = () => {
		this.setState({ openrub: !this.state.openrub })
	}
	Invhide = () => {
		this.setState({ openinv: !this.state.openinv })
	}
	Sorthide = () => {
		this.setState({ opensort: !this.state.opensort })
	}
	Redirtostart = () => {
		this.setState({ path: '/' });
	}
	Radio = (val) => {
		let sort = [];
		sort = this.state.sort;
		for (let i = 0; i < sort.length; i++) {
			if (i == val) sort[i] = true
			else sort[i] = false;
		}
		this.setState({ sort: sort });
		//console.log(this.state.sort);
	}

	FindFranchise = () => {
		let rubs = [];
		let sort;
		//rubs=this.state.rubrics;
		for (let i = 0; i < this.state.rubrics.length; i++) {
			if (this.state.rubrics[i].check)
				rubs.push(this.state.rubrics[i].name)
		}

		// if(rubs.length==0)
		// for(let i=0;i<this.state.rubrics.length;i++){
		// 	rubs.push(this.state.rubrics[i].name)
		// }

		if (this.state.sort[0]) sort = 'standart';
		if (this.state.sort[1]) sort = 'year';
		if (this.state.sort[2]) sort = 'profit';
		if (this.state.sort[3]) sort = 'investments';
		if (this.state.sort[4]) sort = 'lump_sum';
		if (this.state.sort[5]) sort = 'payback_period';
		if (this.state.sort[6]) sort = 'fr_points';
		if (this.state.sort[7]) sort = 'royalty';

		// console.log(rubs, this.state.value.min, this.state.value.max, sort);
		this.props.find(rubs, this.state.value.min, this.state.value.max, sort);
		//this.setState({rubsarray:rubs,path:'/', test:<Redirect to="/"></Redirect>});
		this.setState({ path: "/Find" });
	}

	render() {
		let redirto;
		let alert;
		if (this.props.allowed) alert = 'alertOn'
		else alert = 'alertImg';
		let rubs = this.state.rubrics.map((data) =>
			<div key={data.name} className='rubstyle' onClick={() => this.Onchecked(data.name)}>
				<p style={{ display: "inline-block", verticalAlign: 'middle' }}>{data.name}</p>
				<div className={"check" + data.check} style={{ width: "23px", height: "23px", borderRadius: "6px", display: "inline-block", verticalAlign: 'middle' }}></div>
			</div>
		);

		if (this.state.path) {
			if (this.state.rubsarray) {
				redirto = <Redirect to={{
					pathname: '/',
				}} />
			} else redirto = <Redirect to={this.state.path}></Redirect>
		};



		return (
			<div><Online>

				<div className="unselectable">
					{redirto}
					{this.state.test}
					<div className='header'>
						<div className='backImg'
							//onClick={this.Redirtostart}
							onClick={() => this.props.history.goBack()}

						></div>
						<div className={alert} onClick={this.props.push}></div>
						<div className='onfilterImg'></div>
					</div>
					<div></div>
					<div className='headerpaddindstart'>

						<h1>Фильтр</h1>

						<div className={'filterRubrics filtersizerub' + this.state.openrub}>
							<div className='filpadd' onClick={this.Rubhide}><h2>Рубрики</h2><div className={'seleectarrow deg' + this.state.openrub} style={{ display: "inline-block" }} ></div></div>
							<div className={'filpadd scroll hide' + this.state.openrub}>
								<div className='rubstyle' onClick={this.Allcheck}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Все франшизы</p>
									<div className={"check" + this.state.allcheck} style={{ width: "23px", height: "23px", borderRadius: "6px", display: "inline-block", verticalAlign: 'middle' }}></div>
								</div>
								{rubs}
							</div>
						</div>
						<div className={'filterInvestments filtersizeinv' + this.state.openinv}>
							<div className='filpadd' onClick={this.Invhide}><h2 >Инвестиции:</h2><div className={'seleectarrow deg' + this.state.openinv} style={{ display: "inline-block" }} ></div></div>
							<div className={'hide' + this.state.openinv}>
								<p >{this.state.value.min} p</p>
								<p style={{ textAlign: "right" }}>{this.state.value.max} p</p>
								<InputRange maxValue={parseInt(this.props.maxvalues[0][2], 10)} minValue={parseInt(this.props.mininvest[0][0], 10)} step={10000} value={this.state.value} onChange={value => this.setState({ value })} />
							</div>
						</div>

						<div className={'filterRubrics filtersizerub' + this.state.opensort}>
							<div className='filpadd' onClick={this.Sorthide}><h2 >Сортировка:</h2><div className={'seleectarrow deg' + this.state.opensort} style={{ display: "inline-block" }}></div></div>
							<div className={'filpadd scroll hide' + this.state.opensort}>
								<div className='rubstyle' onClick={() => this.Radio(0)}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Стандартно</p>
									<div className={"radio" + this.state.sort[0]} ></div>
								</div>
								<div className='rubstyle' onClick={() => this.Radio(1)}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Год</p>
									<div className={"radio" + this.state.sort[1]} ></div>
								</div>
								<div className='rubstyle' onClick={() => this.Radio(2)}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Прибыль</p>
									<div className={"radio" + this.state.sort[2]} ></div>
								</div>
								<div className='rubstyle' onClick={() => this.Radio(3)}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Инвестиции</p>
									<div className={"radio" + this.state.sort[3]} ></div>
								</div>
								<div className='rubstyle' onClick={() => this.Radio(4)}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Паушальный взнос</p>
									<div className={"radio" + this.state.sort[4]} ></div>
								</div>
								<div className='rubstyle' onClick={() => this.Radio(5)}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Окупаемость</p>
									<div className={"radio" + this.state.sort[5]} ></div>
								</div>
								<div className='rubstyle' onClick={() => this.Radio(6)}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Количество точек</p>
									<div className={"radio" + this.state.sort[6]} ></div>
								</div>
								<div className='rubstyle' onClick={() => this.Radio(7)}>
									<p style={{ display: "inline-block", verticalAlign: 'middle' }}>Роялти</p>
									<div className={"radio" + this.state.sort[7]} ></div>
								</div>

							</div>
						</div>
						<div className='buttonStyle wrap' onClick={this.FindFranchise}><b>Применить фильтр</b></div>

						<br />
					</div>
				</div>
			</Online>
				<Offline>
					<p className='offline'>Отсутсвует соединение с интернетом</p>
					<div style={{ textAlign: "center" }} > <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>
				</Offline>
			</div>
		);
	}
}
export default Filters;