import React from 'react';
import connect from '@vkontakte/vkui-connect';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import './img/ImgStyle.css';
import axios from 'axios';
import ym from 'react-yandex-metrika';
import NumberFormat from 'react-number-format';
import { Offline, Online } from "react-detect-offline";


class Connect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			load: true,
			path: null,
			banner: false,
			phone: null,
			connect: false,
			franchise: '',
			phone: '',
			getNumber: true,
			email: '',
			getEmail: true,

		}
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.setState({ franchise: this.props.base[this.props.franch] })

		connect.subscribe((e) => {
			switch (e.detail.type) {
				case 'VKWebAppGetPhoneNumberResult':
					this.setState({ phone: e.detail.data.phone_number.slice(1) });
					break;
				case 'VKWebAppGetEmailResult':
					this.setState({ email: e.detail.data.email });
					break;
				default:
					console.log(e.detail.type);
			}
		});

		axios.get('https://franchise.irsib.pro/BackEnd/API.php', {
			params: {
				api: 'req',
				frid: this.props.base[this.props.franch].id,
				access: this.props.access
			}
		});

	}

	Redirtofilter = () => {
		this.setState({ path: '/filters' });
	}
	Redirtostart = () => {
		this.setState({ path: '/franchise' });
	}

	Getphonenumber = () => {
		if (this.state.getNumber) {
			connect.send("VKWebAppGetPhoneNumber", {});
			this.setState({ getNumber: false })
		}
	}
	Getemail = () => {
		if (this.state.getEmail) {
			connect.send("VKWebAppGetEmail", {});
			this.setState({ getEmail: false })
		}
	}

	Request = () => {
		this.setState({ connect: true })
		axios.get('https://franchise.irsib.pro/BackEnd/API.php', {
			params: {
				frid: this.state.franchise.id,
				username: this.props.user.first_name,
				userlastname: this.props.user.last_name,
				userid: this.props.user.id,
				access: this.props.access,
				phone: this.state.phone,
				email: this.state.email,
				api: 6
			}
		})
		ym('reachGoal', 'whateverGoal', { franchise_name: this.state.franchise.name });
	}

	Banner = () => {
		this.setState({ banner: !this.state.banner })
	}


	render() {
		var redirto;
		let alert;
		let fixed;


		if (this.state.banner) fixed = "fixed"
		else fixed = "";

		if (this.props.allowed) alert = 'alertOn'
		else alert = 'alertImg';
		if (!this.state.load)
			return (<div style={{ textAlign: "center" }} > <div class="lds-ring"><div></div><div></div><div></div><div></div></div> </div>)
		else

			if (this.state.path) {
				if (this.state.path == '/franchise') {
					redirto = <Redirect to={{
						pathname: '/franchise',
					}} />
				} else
					redirto = <Redirect to={this.state.path}></Redirect>
			}
		var banner;
		var phonecorrect = false;
		if (this.state.phone) {
			if (this.state.phone.indexOf("*") == -1) phonecorrect = true

		}
		if (this.state.banner && !this.state.connect && (phonecorrect))
			banner = <div className='b-popup'>
				<div className='banner '>
					<br />
					<h2>Вы уверены, что хотите отправить заявку?</h2>
					<br /><br /><br />
					<div className='buttonStyle wrap' onClick={this.Request}><b>Отправить</b></div><br />
					<div className='buttonStyleSenler wrap' onClick={this.Banner}><b>Отменить</b></div>
				</div></div>
		else if (this.state.banner && this.state.connect && (phonecorrect))
			banner = <div className='b-popup'>
				<div className='banner '>
					<br />
					<h2>Ваша заявка успешно отправлена!</h2>
					<p>Мы свяжемся с Вами в ближайшее время</p>
					<div className='buttonStyleSenler wrap' onClick={this.Banner}><b>Продолжить</b></div>
				</div></div>
		else if (this.state.banner && !phonecorrect)
			banner = <div className='b-popup'>
				<div className='banner '>
					<br />
					<h2>Некорректно указан номер!</h2>
					<p>Пожалуйста, введите номер</p>
					<div className='buttonStyleSenler wrap' onClick={this.Banner}><b>Ввести номер</b></div>
				</div></div>
		else banner = null;

		let opt;
		if (this.state.franchise.Optional_button) {
			opt = <a className='buttonStyleSenler wrap' target="_blank" href={this.state.franchise.Optional_button_link} style={{ textDecoration: "none" }}>
				<b>{this.state.franchise.Optional_button}</b>
			</a>
		}

		return (
			<div><Online>
				<div className={"unselectable" + fixed}>
					{redirto}
					{banner}
					<div className='header'>
						<div className='backImg'
							onClick={() => this.props.history.goBack()}
						></div>
						<div className={alert} onClick={this.props.push}></div>
						<Link className='filterImg' to="/filters"></Link>
					</div>
					<div className='headerpaddindstart'>
						<h1>Связаться с Франчайзи</h1>
						<h4 style={{ textAlign: "center" }}>{this.state.franchise.name}</h4>
						<div style={{ textAlign: "center" }}>
							<div style={{ height: "628px", background: "#FFFFFF", boxShadow: "0px 25px 40px rgba(221, 219, 231, 0.5)", borderRadius: "21px", margin: "auto 13px -641px" }}></div>
							<div className='photo' style={{ display: "inline-block", backgroundImage: `url(${this.props.user.photo_200})`, backgroundSize: "cover" }}><div className='photo'></div></div>
							<p className='gray'>Приветствуем</p>
							<h2 className='namestyle'>{this.props.user.first_name}</h2>
							<br />
							<NumberFormat type='tel' onClick={this.Getphonenumber} value={this.state.phone} onValueChange={(values) => { const { formattedValue, value } = values; this.setState({ phone: formattedValue }) }} className='phone' format="+7 (###) ###-####" placeholder="+7 (***) ***-****" mask="*" />
							<br />
							<input onClick={this.Getemail} defaultValue={this.state.email} onChange={(e) => { this.setState({ email: e.currentTarget.value }) }} className='phone' placeholder="email@example.ru" />
							<div className='buttonStyle wrap' onClick={this.Banner}><b>Оставить заявку</b></div>
							<br />
							<br />
							<br />
							{opt}
						</div>
					</div>
				</div>
			</Online>
				<Offline>
					<p className='offline'>Отсутсвует соединение с интернетом</p>
					<div style={{ textAlign: "center" }} > <div className="lds-ring"><div></div><div></div><div></div><div></div></div> </div>
				</Offline>
			</div>
		);
	}
}
export default Connect;